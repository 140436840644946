@tailwind base;
@tailwind components;
@tailwind utilities;


/* import the DropDownButton dependency styles */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

body {
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

root {
  margin: 0px;
  padding: 0px;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
*::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  scroll-margin-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 100vh; */
  @apply bg-gray-500 border-0 border-l-4 border-transparent
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* border-radius: 100vh; */
  @apply border-0 border-l-2 border-gray-500 border-solid bg-gray-600
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-800
}

::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }
